app.controller('eventosCtrl', function ($http, $timeout, $sce, $location, $filter, $rootScope)
{
    var vm = this;
    vm.partes = {

    };

    vm.headlines = [
        {
            titulo: "Você cresceu na era digital, mas trava quando precisa usar o computador? Parece contraditório, mas é a realidade de muitos jovens.",
            id: "jovens_nao_manja",
        },
        {
            titulo: "Por que parece que todo mundo sabe usar o computador, menos você? Spoiler: não é culpa sua – mas você pode resolver isso agora.",
            id: "todo_mundo_sabe_menos_eu",
        },   
        {
            titulo: "Se atualizar no mercado é possível, mas não sem dominar o básico. Você está perdendo tempo e oportunidades por não saber digitar como deveria",
            id: "todo_mundo_sabe_menos_eu",
        },
        {
            titulo: "Enquanto você digita uma frase, outros já terminaram o trabalho. Não é sobre competição – é sobre eficiência.",
            id: "nao_e_competicao_e_eficiencia",
        }, 
        {
            titulo: "Você está perdendo horas todos os dias sem perceber – e tudo por digitar devagar. A lentidão no teclado é mais cara do que você imagina.",
            id: "voce_perde_horas_sem_perceber",
        },
        {
            titulo: "Se você ainda olha para o teclado, está atrasando sua vida. Simples assim: tempo perdido, energia desperdiçada, resultados comprometidos.",
            id: "atraso_de_vida",
        },   
        {
            titulo: "Já pensou em terminar suas tarefas na metade do tempo? Não é mágica, é técnica. E você pode aprender hoje.",
            id: "nao_e_magica_e_tecnica",
        }, 
        {
            titulo: "A forma como você digita está te deixando para trás. Corrigir isso pode transformar sua rotina e abrir novas portas.",
            id: "a_forma_como_digita_te_deixa_para_tras",
        }, 
        {
            titulo: "Seu teclado pode ser seu maior aliado – ou o maior inimigo. A escolha é sua.",
            id: "teclado_aliado_ou_inimigo",
        },
        {
            titulo: "O que você faria com horas extras no seu dia? Aprender a digitar melhor pode te dar exatamente isso.",
            id: "oq_voce_faria_com_horas_extras",
        },
        {
            titulo: "Se você não domina o teclado, está perdendo mais do que produtividade. Tempo, foco e até oportunidades estão em jogo.",
            id: "tempo_foco_oportunidades",
        }, 
        {
            titulo: "Você acha que está no controle, mas o teclado ainda te comanda. Está na hora de inverter isso – e dominar de vez",
            id: "voce_nao_esta_no_comando",
        },  
        {
            titulo: "Por que parece que todo mundo é mais rápido no computador que você? Não é talento, é técnica. E você pode aprender agora.",
            id: "nao_e_talento_e_tecnica",
        }, 
        {
            titulo: "Enquanto você digita devagar, o mundo está correndo. Chegou a hora de acelerar e não ficar para trás.",
            id: "enquanto_voce_digita_o_mundo_corre",
        },
        {
            titulo: "Enquanto você olha para o teclado, outros já estão entregando resultados. Não deixe que algo tão simples te coloque para trás.",
            id: "voce_olha_pro_teclado_outros_entregam",
        },
        {
            titulo: "Seu computador é rápido. Você é? Sem digitação eficiente, toda sua tecnologia é inútil.",
            id: "seu_pc_e_rapido_e_vc",
        },
        {
            titulo: "Seus resultados não melhoram porque você perde tempo no básico. A produtividade que você busca começa pelo teclado.",
            id: "teclado_e_o_basico",
        },

    ];
    
    vm.tempo_timer = '00:00:00';
    vm.timer_rodando = false;

    vm.ocupado = false;

    vm.registrarInView = function(parte, visualizando, variacao) {
        if (visualizando)
        {
            let id = parte+(variacao??'');
            if (vm.partes[id] == undefined)
                vm.partes[id] = {
                    codigo: null,
                    quantidade: 1,
                    variacao: variacao ?? null,
                };
            else
                vm.partes[id].quantidade++;

            $http.post('registrar-evento', {
                pagina: vm.pagina,
                evento: parte,
                valor: vm.partes[id].quantidade,
                variacao: variacao ?? null,
                tempo: null,
                codigo: vm.partes[id].codigo,
            }).then(function successCallback(response)
            {

                vm.partes[id].codigo = response.data.codigo;
                vm.ocupado = false;


            }, function errorCallback(response)
            {
                // called asynchronously if an error occurs
                // or server returns response with an error status.
            });

            // console.log(vm.partes);
        }
    }
    
    vm.timerEmMinutos = function()
    {
        var hora_minuto_segundo = vm.tempo_timer.split(':');

        return (parseInt(hora_minuto_segundo[0]) * 60) +
            parseInt(hora_minuto_segundo[1]) +
            (parseInt(hora_minuto_segundo[2]) / 60);
    };


    vm.iniciarPararTimer = function()
    {
        if (!vm.timer_rodando)
        {

            vm.timer_rodando = true;


            if (vm.pausado)
                vm.pausado = false;
            else
                vm.tempo_timer = null;

            $timeout(contadorTimer, 1);


        }
        else
        {
            vm.timer_rodando = false;
            // vm.salvar(null);
        }
    };


    function pad (str, max) {
        str = str.toString();
        return str.length < max ? pad("0" + str, max) : str;
    }

    function contadorTimer()
    {
        if (vm.timer_rodando)
        {
            if( vm.tempo_atualizacao < 10 )
            {
                vm.tempo_atualizacao += 1;
            }
            else
            {
                vm.tempo_atualizacao = 0;

                if(!vm.ocupado)
                {
                    vm.ocupado = true;

                    var data = {
                        pagina: vm.pagina,
                        evento: 'visualizacao',
                        variacao: vm.variacao ?? null,
                        valor: 1,
                        tempo: vm.tempo_timer ?? '00:00:00',
                        codigo: vm.codigoTempo,
                    };


                    $http.post('registrar-evento', data).then(function successCallback(response)
                    {

                        vm.codigoTempo = response.data.codigo;
                        vm.ocupado = false;


                    }, function errorCallback(response)
                    {
                        // called asynchronously if an error occurs
                        // or server returns response with an error status.
                    });
                }
            }

            var hora = new Date();

            if (vm.tempo_timer == null || vm.tempo_timer == '')
            {
                vm.tempo_timer = '00:00:00';
            }

            var timer = vm.tempo_timer.split(':');

            timer[2] = parseInt(timer[2]) + 1;

            // segundos
            if (timer[2] == 60)
            {
                timer[2] = 0;
                timer[1] = parseInt(timer[1]) + 1;
            }

            // minutos
            if (timer[1] == 60)
            {
                timer[1] = 0;
                timer[0] = parseInt(timer[0]) + 1;
            }

            vm.tempo_timer =
                pad(timer[0], 2) + ":"
                + pad(timer[1], 2) + ":"
                + pad(timer[2], 2);

            // console.log(vm.tempo_timer);

            $timeout(contadorTimer, 1000);
        }
    }

    vm.iniciarPararTimer();

});